<template>
  <Scroll :mobile-only="true" class="scroll-fitness -md:mb-5 -md:visible">
    <div
      class="group_cards pr-[12px] md:px-0 flex max-w-[calc(100vw_-_12px)] overflow-visible md:grid grid-cols-3 gap-3 md:gap-5"
      :class="{ 'grid-cols-3': !narrow, 'grid-cols-2': narrow }"
      data-scroll
      data-scroll-id="group_cards"
    >
      <div
        v-for="card in cards"
        :key="card.name"
        ref="card"
        class="-md:first:ml-3 min-w-[75%] w-9/12 -md:last:min-w-[calc(75%_+_12px)] -md:last:pr-[12px] pb-5 md:pb-0 md:w-full"
      >
        <MoleculeCard class="h-full" :card="card" />
      </div>
    </div>
  </Scroll>
</template>

<script>
import MoleculeCard from '../molecules/MoleculeCard'
import Scroll from '../templates/layouts/Scroll'

export default {
  name: 'OrganismGrid',
  components: {
    MoleculeCard,
    Scroll,
  },
  props: ['cards', 'narrow', 'kidsPage'],
  mounted() {
    this.$root.$loco.on('scroll', this.onScroll)    
  },
  beforeDestroy() {
    this.$root.$loco.off('scroll', this.onScroll)
  },
  methods: {
    onScroll(data) {
      if (!this.$refs.card || !this.$refs.card.length) return
      if (window.innerWidth < 960) return
      const speeds = this.narrow ? [3, 4.5] : [3, 6, 4.5]
      const group = data.currentElements.group_cards
      if (!group) return
      this.$refs.card.forEach((card, i) => {
        const top = (0.5 - group.progress) * 100 * speeds[i % speeds.length]
        card.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${top}, 0, 1)`
      })
    },
  },
}
</script>

<style lang="postcss">
.group_cards {
  transition: opacity 1s var(--cubic-bezier), transform 1s var(--cubic-bezier) 0.3s;
  @apply md:translate-y-1/4 translate-y-0 opacity-30;
  &.is-inview {
    @apply translate-y-0 opacity-100;
  }
}
.scroll-fitness .inset-x-0 {
  @apply !inset-x-3;
}
</style>
