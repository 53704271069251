<template>
  <div class="container mt-44 md:min-h-screen">
    <div class="md:grid grid-cols-12 linear-gradient-gray px-3 md:px-0 pt-12 pb-12 md:pt-48 md:pb-44 rounded-[10px]">
      <div class="col-start-3 col-span-8">
        <div v-if="successShow">
          <AtomTitle class="mb-10 md:mb-16" animation="rotation"> Сообщение <br />отправлено!</AtomTitle>
          <AtomTitle tag="h3" class="max-w-[500px]" animation="rotation">
            В&nbsp;ближайшее время наш менеджер свяжется с&nbsp;вами.
          </AtomTitle>
        </div>
        <div v-else>
          <AtomTitle class="mb-2 md:mb-16" animation="rotation">
            Напишите
            <br />нам
          </AtomTitle>
          <div class="md:grid grid-cols-2">
            <AtomText class="-md:mb-14" content="После отправки сообщения <br /> мы свяжемся с вами." />
            <form @submit.prevent="send">
              <div class="form-input">
                <MoleculeSelect
                  v-model="payload.theme"
                  :error="submitted && error.theme"
                  :items="themes"
                  placeholder="Выберите тему обращения"
                  class="mb-2"
                  @input="validate('theme')"
                />
                <MoleculeSelect
                  v-model="payload.club"
                  :error="submitted && error.club"
                  :items="clubs"
                  placeholder="Выберите клуб"
                  class="mb-2"
                  @input="validate('club')"
                />
                <MoleculeInputText
                  v-model="payload.first_name"
                  :error="submitted && error.first_name"
                  label="Имя*"
                  class="item-input mb-2"
                  type="text"
                  @blur="validate('first_name')"
                  @input="validate('first_name')"
                />
                <MoleculeInputText
                  v-model="payload.email"
                  :error="submitted && error.email"
                  label="Email*"
                  class="item-input mb-2"
                  type="email"
                  @blur="validate('email')"
                  @input="validate('email')"
                />
                <MoleculeInputPhone
                  :error="submitted && error.phone"
                  :value="payload.phone"
                  label="Телефон*"
                  type="tel"
                  theme="white"
                  @onInput="onInputPhone"
                  @blur="onInputPhone"
                />
                <MoleculeTextarea
                  v-model="payload.message"
                  :error="submitted && error.message"
                  label="Сообщение"
                  class="item-input mb-2"
                  @blur="validate('message')"
                  @input="validate('message')"
                />
              </div>
              <div class="controls flex flex-col mt-10 md:gap-y-14 md:mt-14">
                <MoleculeRadio
                  v-model="radio"
                  class="md:mt-3"
                  type="radio"
                  :checked="radio"
                  :error="submitted && error.radio"
                  label="Я соглашаюсь с условиями обработки <br /> персональных данных"
                  @input="error.radio = !radio"
                />
                <AtomButton :disabled="submitting" tag="button" type="submit" class="w-full md:w-auto mt-10 md:mt-0">
                  ОТПРАВИТЬ
                </AtomButton>
              </div>
              <div v-if="errorShow" class="text-red text-center text-sm mt-5">Произошла ошибка. Попробуйте позже</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import AtomText from '../../atoms/AtomText'
import AtomTitle from '../../atoms/AtomTitle'
import MoleculeInputText from '../../molecules/forms/MoleculeInputText'
import MoleculeInputPhone from '../../molecules/forms/MoleculeInputPhone'
import MoleculeRadio from '../../molecules/forms/MoleculeRadio'
import MoleculeSelect from '../../molecules/forms/MoleculeSelect'
import MoleculeTextarea from '../../molecules/forms/MoleculeTextarea'
import { getUtmList } from '@/utils/cookies'

export default {
  name: 'TemplateFormsSend',
  components: {
    AtomTitle,
    AtomText,
    AtomButton,
    MoleculeSelect,
    MoleculeInputText,
    MoleculeInputPhone,
    MoleculeRadio,
    MoleculeTextarea,
  },
  builder: {
    component: 'form-write-us',
  },
  data: () => {
    return {
      successShow: false,
      errorShow: false,
      submitted: false,
      submitting: false,
      radio: false,
      payload: {
        theme: null,
        club: null,
        first_name: '',
        phone: '',
        email: '',
        message: '',
      },
      error: {
        theme: null,
        club: null,
        first_name: null,
        phone: null,
        email: null,
        message: null,
        radio: null,
      },
    }
  },
  computed: {
    clubs() {
      return this.$store.getters['modules/content/getClubsAsListForm']
    },
    themes() {
      return this.$store.getters['modules/content/getWriteUsSubjects']
    },
  },
  methods: {
    send() {
      this.errorShow = false
      this.submitted = true
      if (!this.submitting && this.validateForm()) {
        this.submitting = true

        const phone = `+${this.payload.phone.replace(/[^0-9]/g, '')}`
        let visitorUid = 'unknown'
        try {
          visitorUid = window.AMOPIXEL.getVisitorUid()
        } catch (e) {}

        const utmList = getUtmList(this.$cookies)

        const payload = {
          ...this.payload,
          phone,
          theme: this.payload.theme.text,
          club: this.payload.club.slug,
          visitor_uid: visitorUid,
          ...utmList,
        }

        const sessionId = window?.ct('calltracking_params', 'b4d68ca9')?.sessionId || 0

        if (sessionId) {
          payload.sessionId = sessionId
        }

        let clientId = 0
        try {
          clientId = window?.yaCounter37069155?.getClientID?.() ?? this.$cookies?.get('_ym_uid') ?? 0
        } catch (error) {
          console.error('clientId:', error)
        }

        if (clientId) {
          payload.clientId = String(clientId)
        }

        let roistatVisit = 'nocookie'
        try {
          roistatVisit = this.$cookies?.get('roistat_visit') || 'nocookie'
        } catch (error) {
          console.error('roistat_visit:', error)
        }

        payload.roistat_visit = String(roistatVisit)

        this.$axios
          .post('/api/forms/write', {
            data: payload,
          })
          .then(() => {
            this.successShow = true
            this.sendReachGoal('success-form-write', {
              api: 'forms/write',
              theme: payload.theme.text,
              club: payload.club,
            })
          })
          .catch(() => {
            this.errorShow = true
          })
          .finally(() => {
            this.submitting = false
          })
      }
    },
    validateForm() {
      Object.keys(this.payload).forEach(key => {
        this.validate(key)
      })
      this.error.radio = !this.radio
      return !Object.values(this.error).some(Boolean)
    },
    validate(key) {
      const textIsInvalid = v => !(v || '').trim()
      const schema = {
        theme: v => !v,
        club: v => !v,
        first_name: textIsInvalid,
        phone: v => !v || v.length !== 16,
        email: textIsInvalid,
        message: textIsInvalid,
      }
      if (key in this.error && schema[key]) {
        const value = this.payload[key]
        this.error[key] = schema[key](value)
      }
    },
    onInputPhone(value, objValidPhone) {
      this.payload.phone = value

      if (!this.payload.phone || !objValidPhone.isValid) this.error.phone = !objValidPhone.isValid
      else this.error.phone = false
    },
  },
}
</script>
